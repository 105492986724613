.section-sections--17222669205666__custom_announcement_bar_an9KVg {
    background-color: #24397e;
    color: #ffffff;
  }
  .custom-scrool-section {
    padding-top:10px;
    padding-bottom:10px;
  }

  .custom-scrool-section--slide .custom-scrool-section--heading {
    font-size: 12px;
    color: rgb(var(--color-foreground));
  }

  #section-id-sections--17222669205666__custom_announcement_bar_an9KVg.custom-scrool-section--scroll .custom-scrool-section-slide-track:hover{
    animation-play-state: initial;
  }

.custom-scrool-section {
    --space: 3.5rem;
    display: grid;
    align-content: center;
    overflow: hidden;
    gap: var(--space);
    width: 100%;
    font-size: 1.5rem;
    line-height: 1.5
}

.custom-scrool-section .custom-scrool-section--scroll {
    --duration: 10s;
    --gap: var(--space);
    display: flex;
    overflow: hidden;
    -webkit-user-select: none;
    user-select: none;
    gap: var(--gap)
}

.custom-scrool-section .custom-scrool-section-slide-track {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: var(--gap);
    min-width: 100%;
    animation: scroll var(--duration) linear infinite
}

@keyframes scroll {
    0% {
        transform: translate(0)
    }

    to {
        transform: translate(calc(-100% - var(--gap)))
    }
}

.custom-scrool-section--scroll .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px
}